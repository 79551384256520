<template>
  <div class="base-carousel">
    <el-carousel
     :height="setHeight"
     :interval="setIntervalTime"
     :direction="setDirection"
     :indicator-position="openIndicator"
    >
      <el-carousel-item v-for="item in imageList" :key="item.id">
        <img class="carousel-img" :src="item.url" @click="handleClick(item.website)">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'BaseCarousel',
  props: {
    imageList: {
      type: Array,
      required: true,
      default: () => [{
        id: 1,
        url: '',
      }],
    },
    setHeight: {
      type: String,
    },
    setIntervalTime: {
      type: Number,
      default: 3000, // 3秒
    },
    setDirection: {
      type: String,
      default: 'horizontal', // horizontal / vertical
    },
    openIndicator: {
      type: String,
      default: 'none', // none / outside
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.base-carousel, .el-carousel, /deep/ .el-carousel__container {
  height: 100%;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.carousel-img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-size: cover;
}
</style>
