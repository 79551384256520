<template>
  <div id="base-swiper" :style="`width: ${swiperWidth}; height: ${scrollHeight}px;`">
    <ul id="scroll" :style="`height: ${scrollHeight}px;`">
      <li class="image-item" v-for="(item, index) in imageList" :key="`base-swiper-${index}`"
        :style="`padding-right: ${imagePaddingRight}px`"
        @mouseover="stop"
        @mouseleave="play"
      >
        <a :href="item.website ? item.website : '#'" target="_blank">
          <img :src="item.url" :style="`width: ${imageWidth}px; height: ${imageHeight}px;`"
           :alt="item.website">
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BaseSwiper',
  props: {
    imageList: {
      type: Array,
      required: true,
      default: () => [],
    },
    swiperSpeed: {
      type: Number,
      default: 50,
    },
    swiperWidth: {
      type: String,
    },
    swiperHeight: {
      type: String,
    },
    imageWidth: {
      type: Number,
      default: 108,
    },
    imageHeight: {
      type: Number,
      default: 128,
    },
    imagePaddingRight: {
      type: Number,
      default: 9,
    },
  },
  data() {
    return {
      timer: null,
      swiper: null,
      ul: null,
      ulItem: {},
      lis: null,
      itemCount: 0,
      itemWidth: 0,
      scrollHeight: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(this.init());
    }, 500);
  },
  methods: {
    getElement(el) {
      return document.getElementById(el);
    },
    init() {
      this.scrollHeight = this.imageHeight + 2 * 2 + 1 * 2;
      this.swiper = this.getElement('base-swiper');
      if (this.swiperWidth === undefined) {
        this.swiper.style.width = (this.imageList.length - 1) * (this.imageWidth + 2 + 2
        + this.imagePaddingRight) - this.imagePaddingRight + 'px';
      }
      const scroll = this.getElement('scroll');
      this.ul = scroll;
      this.lis = scroll.getElementsByTagName('li');
      this.ulItem = this.lis[0];
      if (this.ulItem) {
        this.itemWidth = this.ulItem.offsetWidth;
      }
      this.itemCount = document.querySelectorAll('.image-item').length;
      this.ul.style.width = this.imageWidth * (this.itemCount + 1) + this.imagePaddingRight + 'px';
      this.play();
    },
    marquee() {
      this.swiper.scrollLeft += 2;
      if (this.swiper.scrollLeft % this.itemWidth <= 1) {
        const item = this.getElement('scroll').getElementsByTagName('li')[0];
        this.ul.appendChild(item);
        this.swiper.scrollLeft = 0;
      }
    },
    play() {
      const swiperWidth = this.swiper.style.width.replace('px', '');
      const ulWidth = this.ul.style.width.replace('px', '');
      if (Number(ulWidth) > Number(Number(swiperWidth) + this.imageWidth)) {
        this.timer = setInterval(this.marquee, this.swiperSpeed);
      }
    },
    stop() {
      clearInterval(this.timer);
    },
  },
  destroyed() {
    this.stop();
  },
};
</script>

<style lang="scss" scoped>
#base-swiper {
  box-sizing: border-box;
  margin: auto;
  padding: 0;
  overflow: hidden;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    float: left;

    img {
      padding: 2px;
      background: #fff;
      border: 1px solid #ddd;
    }
  }
}

</style>
