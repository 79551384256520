<template>
  <div class="">
    <el-button type="primary">按钮</el-button>
    <p>轮播图</p>
    <div class="parent-box">
      <base-carousel :imageList="carouselList" />
    </div>
    <p>广告图</p>
    <div class="ad-box">
      <base-swiper :imageList="carouselList" :imageWidth="108" />
    </div>
    <p>基础Box</p>
    <base-box box-width="300px" box-height="200px" />
    <p @click="dialogVisible = true" style="cursor:pointer">基础Dialog, 点我打开</p>
    <base-dialog
      :baseDialogVisible="dialogVisible"
      @closeDialog="handleCloseDialog"
    >
      <span slot="dialog-title">Demo</span>
      <div>
        <p>内容</p>
      </div>
      <span slot="dialog-footer">
        <el-button>按钮1</el-button>
        <el-button type="primary">按钮2</el-button>
      </span>
    </base-dialog>
  </div>
</template>

<script>
import BaseCarousel from '@/components/BaseCarousel/index.vue';
import BaseSwiper from '@/components/BaseSwiper/index.vue';
import BaseBox from '@/components/BaseBox/index.vue';
import BaseDialog from '@/components/BaseDialog/index.vue';

export default {
  naem: 'Demo',
  components: {
    BaseCarousel,
    BaseSwiper,
    BaseBox,
    BaseDialog,
  },
  data() {
    return {
      carouselList: [
        {
          id: 1,
          url: 'https://dss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1089874897,1268118658&fm=26&gp=0.jpg',
        },
        {
          id: 2,
          url: 'https://dss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1819216937,2118754409&fm=26&gp=0.jpg',
        },
        {
          id: 3,
          url: 'https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg',
        },
        {
          id: 4,
          url: 'https://dss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1089874897,1268118658&fm=26&gp=0.jpg',
        },
        {
          id: 5,
          url: 'https://dss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1819216937,2118754409&fm=26&gp=0.jpg',
        },
        {
          id: 6,
          url: 'https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg',
        },
      ],
      dialogVisible: false,
    };
  },
  methods: {
    handleCloseDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.parent-box {
  width: 500px;
  margin: 0 auto;
}
</style>
